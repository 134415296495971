import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { Box, BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconCheck from '@components/atoms/Icons/IconCheck'
import ListItem from '@components/atoms/ListItem'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import shuffleArray from '@helper/array'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  Answer,
  AnswerRow,
  Answers,
  AnswerText,
  BigIconLargeSun,
  Question,
  Questionhead,
  Questionheadline,
  QuestionId,
  Questions,
  Result,
  ResultBox,
  Root,
  SameHeightCol,
  SameHeightRow,
  SkinBox,
  SkinBoxHeadline,
  SkinBoxListItemText,
  SkinBoxText,
  SkinBoxUnorderedList,
  SmallIconLargeSun,
  Test,
} from './SkinTypeTest.styles'

interface Props extends PropsWithClassName {
  dev?: boolean
}

interface SkinTypeTestAnswer {
  answer: string
  value: number
  icon?: React.ReactNode
}

interface SkinTypeTestQuestion {
  question: string
  answers: SkinTypeTestAnswer[]
}

export interface SkinType {
  id: number
  type: string
  colorName: string
  sunReaction: string
  commonFeatures: string[]
  colorHex: string
}

const allSkinTypes: SkinType[] = [
  {
    id: 1,
    type: 'Typ I',
    colorName: 'Sehr hell, blass weiß',
    sunReaction: 'Verbrennt immer, bräunt nie',
    commonFeatures: [
      'Sommersprossen',
      'rotes oder blondes Haar',
      'blaue oder grüne Augen',
    ],
    colorHex: '#FFFBF0', // sehr helles Beige
  },
  {
    id: 2,
    type: 'Typ II',
    colorName: 'Hell, weiß',
    sunReaction: 'Verbrennt meistens, bräunt schwer',
    commonFeatures: [
      'Blaue, grüne oder haselnussfarbene Augen',
      'blondes oder braunes Haar',
    ],
    colorHex: '#FFEBDC', // helles Beige
  },
  {
    id: 3,
    type: 'Typ III',
    colorName: 'Cremeweiß, heller Teint mit jeder Augen- oder Haarfarbe',
    sunReaction:
      'Verbrennt manchmal, bräunt sich allmählich zu einem hellbraun',
    commonFeatures: ['Große Auswahl an Haar- und Augenfarben'],
    colorHex: '#FDD9B5', // mittleres Beige
  },
  {
    id: 4,
    type: 'Typ IV',
    colorName: 'Mäßiges Braun',
    sunReaction: 'Verbrennt minimal, bräunt leicht zu einem mäßigen Braun',
    commonFeatures: [
      'Typisch für mediterrane, asiatische und lateinamerikanische Hintergründe',
    ],
    colorHex: '#D2A172', // hellbraun
  },
  {
    id: 5,
    type: 'Typ V',
    colorName: 'Dunkelbraun',
    sunReaction: 'Verbrennt selten, bräunt sehr leicht',
    commonFeatures: [
      'Nahöstlicher, einiger afrikanischer und indischer Hintergrund',
    ],
    colorHex: '#8D5524', // mittleres Braun
  },
  {
    id: 6,
    type: 'Typ VI',
    colorName: 'Tief pigmentiertes dunkles Braun bis dunkelstes Braun',
    sunReaction: 'Verbrennt nie, bräunt sehr dunkel',
    commonFeatures: ['Afrikanischer Abstammung'],
    colorHex: '#3C2E28', // dunkelstes Braun
  },
]

const DummyQuestionsSkinTypeTest: SkinTypeTestQuestion[] = [
  {
    question: 'Wie reagiert Ihre Haut beim ersten Sonnenkontakt?',
    answers: [
      {
        answer: 'Immer Verbrennungen, nie Bräunung',
        value: 1,
      },
      {
        answer: 'Meist Verbrennungen, selten Bräunung',
        value: 2,
      },
      {
        answer: 'Manchmal leichte Verbrennungen, allmähliche Bräunung',
        value: 3,
      },
      {
        answer: 'Selten Verbrennungen, immer Bräunung',
        value: 4,
      },
      { answer: 'Sehr selten Verbrennungen, schnelle Bräunung', value: 5 },
      { answer: 'Nie Verbrennungen, sehr dunkle Bräunung', value: 6 },
    ],
  },
  {
    question: 'Welche Augenfarbe haben Sie?',
    answers: [
      { answer: 'Hellblau, Hellgrau oder Hellgrün', value: 1 },
      { answer: 'Blau, Grau oder Grün', value: 2 },
      { answer: 'Haselnussbraun oder Hellbraun', value: 3 },
      { answer: 'Dunkelbraun', value: 4 },
      { answer: 'Dunkelbraun bis fast Schwarz', value: 5 },
      { answer: 'Schwarz', value: 6 },
    ],
  },
  {
    question: 'Welche natürliche Haarfarbe haben Sie?',
    answers: [
      { answer: 'Rot oder Hellblond', value: 1 },
      { answer: 'Blond', value: 2 },
      { answer: 'Hellbraun oder Braun', value: 3 },
      { answer: 'Dunkelbraun', value: 4 },
      { answer: 'Schwarz', value: 5 },
      { answer: 'Schwarz mit blauem Schimmer', value: 6 },
    ],
  },
  {
    question: 'Wie oft bekommen Sie Sonnenbrand?',
    answers: [
      { answer: 'Immer', value: 1 },
      { answer: 'Oft', value: 2 },
      { answer: 'Manchmal', value: 3 },
      { answer: 'Selten', value: 4 },
      { answer: 'Sehr selten', value: 5 },
      { answer: 'Nie', value: 6 },
    ],
  },
  {
    question: 'Wie schnell bräunt sich Ihre Haut?',
    answers: [
      { answer: 'Nie', value: 1 },
      { answer: 'Langsam und kaum sichtbar', value: 2 },
      { answer: 'Mäßig und gleichmäßig', value: 3 },
      { answer: 'Schnell und tief', value: 4 },
      { answer: 'Sehr schnell und sehr tief', value: 5 },
      { answer: 'Sofort und sehr dunkel', value: 6 },
    ],
  },
  {
    question: 'Haben Sie Sommersprossen?',
    answers: [
      { answer: 'Sehr viele', value: 1 },
      { answer: 'Viele', value: 2 },
      { answer: 'Einige', value: 3 },
      { answer: 'Wenige', value: 4 },
      { answer: 'Fast keine', value: 5 },
      { answer: 'Keine', value: 6 },
    ],
  },
  {
    question: 'Wie reagiert Ihre Haut nach längerer Sonneneinstrahlung?',
    answers: [
      { answer: 'Starke Rötung und Schmerz', value: 1 },
      { answer: 'Rötung, später leichte Bräunung', value: 2 },
      { answer: 'Klare Bräunung', value: 3 },
      { answer: 'Tiefe Bräunung', value: 4 },
      { answer: 'Sehr tiefe Bräunung', value: 5 },
      { answer: 'Dunkelbraun ohne Rötung', value: 6 },
    ],
  },
  {
    question: 'Bekommen Sie einen Sonnenbrand an bewölkten Tagen?',
    answers: [
      { answer: 'Ja, immer', value: 1 },
      { answer: 'Ja, meistens', value: 2 },
      { answer: 'Manchmal', value: 3 },
      { answer: 'Selten', value: 4 },
      { answer: 'Sehr selten', value: 5 },
      { answer: 'Nie', value: 6 },
    ],
  },
  {
    question: 'Können Sie sich ohne Sonnenbrand sonnen?',
    answers: [
      { answer: 'Nie', value: 1 },
      { answer: 'Kurze Zeit', value: 2 },
      { answer: 'Manchmal länger', value: 3 },
      { answer: 'Lange Zeit', value: 4 },
      { answer: 'Sehr lange Zeit', value: 5 },
      { answer: 'Unbegrenzt', value: 6 },
    ],
  },
  {
    question: 'Wie empfindlich ist Ihre Haut gegenüber der Sonne?',
    answers: [
      { answer: 'Sehr empfindlich', value: 1 },
      { answer: 'Empfindlich', value: 2 },
      { answer: 'Normal', value: 3 },
      { answer: 'Unempfindlich', value: 4 },
      { answer: 'Sehr unempfindlich', value: 5 },
      { answer: 'Absolut unempfindlich', value: 6 },
    ],
  },
]

const SkinTypeTest: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [done, setDone] = useState<boolean>(false)
  const [score, setScore] = useState<number>(0)
  const [index, setIndex] = useState<number>(0)
  const [result, setResult] = useState<number>(0)
  const [shuffledArray, setShuffledArray] = useState<SkinTypeTestQuestion[]>([])

  const questionCount: number = DummyQuestionsSkinTypeTest.length

  const scrollRef = useRef<HTMLDivElement>(null)

  const { dev } = props

  const scrollToTarget = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const handleSetScore = (value: number): void => {
    const newScore = score + value
    const newResult = newScore / questionCount
    if (newResult < 0 || newResult > allSkinTypes.length) {
      return
    }
    setScore(newScore)
    setResult(newResult)
  }

  const handleSelectAnswer = (value: number, indexQuestion: number): void => {
    const newScore = score + value
    setScore(newScore)
    setResult(newScore / questionCount)
    if (indexQuestion + 1 === questionCount) {
      setDone(true)
    } else {
      setIndex(index + 1)
    }
    scrollToTarget()
  }
  const resultRounded = Math.floor(result)

  useEffect(() => {
    const newShuffledArray = shuffleArray(DummyQuestionsSkinTypeTest)
    // const shuffledQuestions = newShuffledArray.map((question) => {
    //   const shuffledAnswers = shuffleArray(question.answers)
    //   return { ...question, answers: shuffledAnswers }
    // })
    setShuffledArray(newShuffledArray)
  }, [setShuffledArray])

  return (
    <Root className={props.className} data-testid={'skin-type-test-root'}>
      {dev && (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <p>
            score: {score}
            <br />
            index: {index}
            <br />
            result: {result}
            <br />
            resultRounded {resultRounded}
            <br />
            allSkinTypesCount {allSkinTypes.length}
            <br />
            resultRounded.type {allSkinTypes[resultRounded].type}
            <br />
            {allSkinTypes.length}
          </p>
          <div>
            <label htmlFor="done">
              <input
                id="done"
                placeholder={'Set done'}
                type="checkbox"
                checked={done}
                onChange={(event) => setDone(event.target.checked)}
              />
              done
            </label>
          </div>
          <div>
            <button onClick={() => handleSetScore(-5)}>Minus</button>
            <button onClick={() => handleSetScore(5)}>Plus</button>
          </div>
        </div>
      )}
      <Test ref={scrollRef}>
        {!done && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={HeadlineImportances.h2}>
                Dein Test:
              </Headline>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Questions>
                {shuffledArray.map((question, indexQuestion) => {
                  return (
                    <Question
                      key={question.question + indexQuestion}
                      background={BoxBackgrounds.GREYLIGHT}
                      visible={indexQuestion === index}
                    >
                      <Questionhead>
                        <Questionheadline importance={HeadlineImportances.h5}>
                          {question.question}
                        </Questionheadline>
                        <QuestionId variant={TextVariants.paragraph}>
                          {indexQuestion + 1}/{questionCount}
                        </QuestionId>
                      </Questionhead>
                      <Answers>
                        <AnswerRow>
                          {question.answers.map((answer, indexAnswer) => {
                            return (
                              <Col
                                key={
                                  answer.answer + indexQuestion + indexAnswer
                                }
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                              >
                                <Answer
                                  delay={indexQuestion}
                                  onClick={() =>
                                    handleSelectAnswer(
                                      answer.value,
                                      indexQuestion
                                    )
                                  }
                                  icon={
                                    answer.icon ?? (
                                      <AnswerText variant={TextVariants.lead}>
                                        <b>{indexAnswer + 1}</b>
                                      </AnswerText>
                                    )
                                  }
                                  background={BoxBackgrounds.WHITE}
                                  key={
                                    answer.answer + indexQuestion + indexAnswer
                                  }
                                >
                                  <AnswerText variant={TextVariants.paragraph}>
                                    {answer.answer}
                                  </AnswerText>
                                </Answer>
                              </Col>
                            )
                          })}
                        </AnswerRow>
                      </Answers>
                    </Question>
                  )
                })}
              </Questions>
            </Col>
          </Row>
        )}
        {done && (
          <>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Headline importance={HeadlineImportances.h2}>
                  Dein Ergebnis:
                </Headline>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <ResultBox
                  background={
                    resultRounded < allSkinTypes.length
                      ? allSkinTypes[resultRounded].colorHex
                      : allSkinTypes[allSkinTypes.length - 1].type
                  }
                >
                  <Result>
                    <SkinBoxHeadline importance={HeadlineImportances.h1}>
                      {allSkinTypes[resultRounded].type}
                    </SkinBoxHeadline>
                    <SkinBoxHeadline importance={HeadlineImportances.h2}>
                      {allSkinTypes[resultRounded].colorName}
                    </SkinBoxHeadline>
                    <SkinBoxText variant={TextVariants.lead}>
                      {allSkinTypes[resultRounded].sunReaction}
                    </SkinBoxText>
                    <SkinBoxUnorderedList
                      background={allSkinTypes[resultRounded].colorHex}
                    >
                      {allSkinTypes[resultRounded].commonFeatures.map(
                        (commonFeature, index) => {
                          return (
                            <ListItem
                              icon={<IconCheck />}
                              key={index + commonFeature}
                            >
                              <SkinBoxListItemText variant={TextVariants.lead}>
                                {commonFeature}
                              </SkinBoxListItemText>
                            </ListItem>
                          )
                        }
                      )}
                    </SkinBoxUnorderedList>
                    <SkinBoxText variant={TextVariants.lead}>
                      <b>{`${result.toFixed(2)} Punkte`}</b>
                    </SkinBoxText>
                  </Result>
                  <SmallIconLargeSun
                    fill={allSkinTypes[resultRounded].colorHex}
                  />
                  <BigIconLargeSun
                    fill={allSkinTypes[resultRounded].colorHex}
                  />
                </ResultBox>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Headline importance={HeadlineImportances.h5}>
                  Fitzpatrick-Hauttypskala
                </Headline>
                <Text variant={TextVariants.paragraph}>
                  Die Hauttypen des Menschen werden häufig anhand der
                  Fitzpatrick-Hauttypskala klassifiziert, die 1975 von Thomas
                  Fitzpatrick, MD, von der Harvard Medical School entwickelt
                  wurde. Die Fitzpatrick-Skala kategorisiert den Hauttyp
                  basierend darauf, wie er auf ultraviolettes (UV) Licht
                  reagiert, insbesondere seine Tendenz zu verbrennen oder zu
                  bräunen.
                </Text>
              </Col>
            </Row>
            <SameHeightRow>
              {allSkinTypes.map((skintype) => {
                return (
                  <SameHeightCol
                    key={skintype.id + skintype.colorName}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                  >
                    <SkinBox
                      key={skintype.colorName + skintype.type}
                      background={skintype.colorHex}
                    >
                      <SkinBoxHeadline importance={HeadlineImportances.h3}>
                        {skintype.type}
                      </SkinBoxHeadline>
                      <SkinBoxText variant={TextVariants.lead}>
                        {skintype.colorName}
                      </SkinBoxText>
                      <SkinBoxText variant={TextVariants.paragraph}>
                        {skintype.sunReaction}
                      </SkinBoxText>
                      <SkinBoxUnorderedList background={skintype.colorHex}>
                        {skintype.commonFeatures.map((commonFeature, index) => {
                          return (
                            <ListItem
                              icon={<IconCheck />}
                              key={index + commonFeature}
                            >
                              <SkinBoxListItemText
                                variant={TextVariants.paragraph}
                              >
                                {commonFeature}
                              </SkinBoxListItemText>
                            </ListItem>
                          )
                        })}
                      </SkinBoxUnorderedList>
                      <SkinBoxText variant={TextVariants.meta}>
                        {skintype.id - 1} - {skintype.id} Punkte
                      </SkinBoxText>
                    </SkinBox>
                  </SameHeightCol>
                )
              })}
            </SameHeightRow>
          </>
        )}
      </Test>
    </Root>
  )
}

export { SkinTypeTest }
