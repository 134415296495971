import styled, { css } from 'styled-components'

import { shouldUseWhiteText } from '@helper/color'
import { Farben } from '@helper/enums/storyblok/Farben'
import { userSelectNone } from '@helper/mixins'

import { BoxBackgrounds } from '../Box/Box'
import { ButtonModifier } from './Button'

export const ButtonPrimaryStyles = () => {
  return css`
    background: ${(props) => props.theme.palette.default.primary};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.primary)
        ? props.theme.palette.default.white
        : props.theme.palette.default.black};
    font-family: ${(props) => props.theme.font.primaryFamily};
    padding: 1rem 1.5rem 0.6875rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    text-align: center;
    svg {
      path {
        fill: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      g {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      circle {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      rect {
        fill: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
    }
    &:hover {
      background: ${(props) => props.theme.palette.default.black};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.black)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
    &:active {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
  `
}

export const ButtonSecondaryStyles = () => {
  return css`
    background: ${(props) => props.theme.palette.default.white};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.white)
        ? props.theme.palette.default.white
        : props.theme.palette.default.black};
    padding: 1rem 1.5rem 0.6875rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    text-align: center;
    svg {
      path {
        fill: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.white)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      g {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.white)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      circle {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.white)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
    }
    &:hover {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
    &:active {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
    &:active {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
  `
}

export const ButtonTertiaryStyles = () => {
  return css`
    background: ${(props) => props.theme.palette.default.black};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.black)
        ? props.theme.palette.default.white
        : props.theme.palette.default.black};
    padding: 1rem 1.5rem 0.6875rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    text-align: center;
    svg {
      path {
        fill: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.black)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      g {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.black)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      circle {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.black)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
    }
    &:hover {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
    &:active {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
  `
}

export const ButtonSmallStyles = () => {
  return css`
    background: ${(props) => props.theme.palette.default.primary};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
    padding: 0.4375rem 1.5rem 0.3125rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    text-align: left;
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.primary)
        ? props.theme.palette.default.white
        : props.theme.palette.default.black};
    svg {
      path {
        fill: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      g {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      circle {
        stroke: ${(props) =>
          shouldUseWhiteText(props.theme.palette.default.primary)
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
    }
    &:hover {
      background: ${(props) => props.theme.palette.default.black};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.black)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
    &:active {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
  `
}

export const ButtonMinimalStyles = (
  background: BoxBackgrounds | Farben | string | undefined
) => {
  return css`
    background: none;
    border: 0.0625rem solid transparent;
    padding: 0.4375rem 1.5rem 0.3125rem 1.5rem;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    text-align: left;
    ${Span} {
      color: ${(props) =>
        shouldUseWhiteText(background ?? '#ffffff')
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
    }
    svg {
      path {
        fill: ${(props) =>
          shouldUseWhiteText(background ?? '#ffffff')
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      g {
        stroke: ${(props) =>
          shouldUseWhiteText(background ?? '#ffffff')
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
      circle {
        stroke: ${(props) =>
          shouldUseWhiteText(background ?? '#ffffff')
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      }
    }
    &:hover {
      background: none;
      border: 0.0625rem solid transparent;
      ${Span} {
        color: ${(props) =>
          shouldUseWhiteText(background ?? '#ffffff')
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
        text-decoration: underline;
      }
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(background ?? '#ffffff')
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(background ?? '#ffffff')
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(background ?? '#ffffff')
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
    &:active {
      background: none;
      border: 0.0625rem solid
        ${(props) =>
          shouldUseWhiteText(background ?? '#ffffff')
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
      ${Span} {
        color: ${(props) =>
          shouldUseWhiteText(background ?? '#ffffff')
            ? props.theme.palette.default.white
            : props.theme.palette.default.black};
        text-decoration: underline;
      }
      svg {
        path {
          fill: ${(props) =>
            shouldUseWhiteText(background ?? '#ffffff')
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        g {
          stroke: ${(props) =>
            shouldUseWhiteText(background ?? '#ffffff')
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
        circle {
          stroke: ${(props) =>
            shouldUseWhiteText(background ?? '#ffffff')
              ? props.theme.palette.default.white
              : props.theme.palette.default.black};
        }
      }
    }
  `
}

interface RootProps {
  modifier: ButtonModifier
  background?: BoxBackgrounds | Farben | string | undefined
}

export const getButtonModifierStyles = (props: RootProps) => {
  switch (props.modifier) {
    case ButtonModifier.PRIMARY:
      return ButtonPrimaryStyles()
    case ButtonModifier.SECONDARY:
      return ButtonSecondaryStyles()
    case ButtonModifier.TERTIARY:
      return ButtonTertiaryStyles()
    case ButtonModifier.SMALL:
      return ButtonSmallStyles()
    case ButtonModifier.MINIMAL:
      return ButtonMinimalStyles(props.background)
    default:
      return ButtonPrimaryStyles()
  }
}

export const Root = styled.button<RootProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  border-radius: 30px;
  cursor: pointer;
  font-family: ${(props) => props.theme.font.primaryFamily};
  margin-bottom: ${(props) => props.theme.margin.default};
  margin-right: ${(props) => props.theme.margin.small};
  ${(props) => getButtonModifierStyles(props)}
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  svg {
    width: auto;
    height: 24px;
  }
`

interface SpanProps {
  background: BoxBackgrounds | Farben | string | undefined
}

export const Span = styled.span<SpanProps>`
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  white-space: nowrap;
  ${userSelectNone()}
`

export const IconLeft = styled.div`
  margin-right: 0.3125rem;
  width: 24px;
  height: 24px;
  display: block;
`
export const IconRight = styled.div`
  margin-left: 0.3125rem;
  width: 24px;
  height: 24px;
  display: block;
`
